import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletProvider } from './contexts/WalletContext'
import Layout from "./Layout/Layout"
import ItemDetailOne from "./Pages/ItemDetailOne"
import ItemDetailTwo from "./Pages/ItemDetailTwo"
import Shop from "./Pages/Shop"
import ShopTwo from "./Pages/ShopTwo"
import AdminForm from "./Pages/Admin/AdminForm"

import LeaderBoard from "./Pages/LeaderBoard"
import Tasks from "./Pages/Tasks"
import { initializeConnector } from '@web3-react/core'
import { MetaMask } from '@web3-react/metamask'


const getLibrary = (provider) => {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
};

export const [metaMask, hooks] = initializeConnector((actions) => new MetaMask({ actions }));
const connectors = [[metaMask, hooks]]

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary} connectors={connectors}>
    <WalletProvider>
    <Router>
    <Routes>
      <Route path="/" element={<Layout />}>
      <Route index element={<ShopTwo />} />
      <Route path="/leaderboard" element={<LeaderBoard />} />
      <Route path="/tasks" element={<Tasks />} />
      <Route path="/shoptwo" element={<Shop />} />
      <Route path="/adminform" element={<AdminForm />} />
      <Route path="/shop" element={<ShopTwo />} >
          <Route index element={<ShopTwo />} />
          <Route path=":id" element={<ShopTwo />} />
      </Route>


      </Route>
    </Routes>
    </Router>
    </WalletProvider>
    </Web3ReactProvider>
  );
}

export default App;
