import { useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers"
import { useWalletCustom } from "../contexts/WalletContext"
import { connectWallet } from "../contexts/walletService"
import { metaMask } from "../App"
import Web3 from "web3";
import { ethers } from "ethers"
import { formatAddress, formatBalance } from './utils'

// ConnectButton.js
// import { useWalletCustom } from './WalletContext';

const ConnectButton = () => {
  const { isConnected, isValidChain, connect, switchNetwork, balance } = useWalletCustom();
  const [loading, setLoading] = useState(false)
  const { activate, account, library, chainId, active } = useWeb3React()

  const handleClick = () => {
    if (!isConnected) {
      connect();
    } else if (!isValidChain) {
      switchNetwork();
    }
  };

  const handleConnect = async () => {
    await connectWallet(metaMask);
  };

  console.log('account: ' + account)
  console.log('library: ' + library)
  console.log('chainId:', chainId);
  console.log('active:', active);

   // <button onClick={handleClick}>
   //    {!isConnected ? 'Connect' : !isValidChain ? 'Switch to zkSync' : 'Connected'}
   //  </button>

  return (
     <>
      {isConnected ? (
        <>
         <div className="flex items-center bg-gray-100 px-4 py-1 rounded-full">
       <div className="flex items-center">
         <i className="bx bx-wallet mr-2" /> {balance}
       </div>
       <div
         className="flex items-center bg-blue-500 text-white px-2 py-1 ml-4 rounded-full border border-blue-500 cursor-pointer hover:bg-gray-100 hover:text-gray-600 hover:border-blue-600"
          
       >
         {formatAddress(account)}
       </div>
     </div>
        </>
      ) : (   
        <button
          type="button"
          className={`ml-3 inline-flex items-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 ${loading ? "loading" : ""}`}
          disabled={!!loading}
          onClick={handleConnect}
        >
          
              Connect Wallet
           
        </button>
      )}
    </>
  );


}

export default ConnectButton

//
// const ConnectButton = ({ isActive, connector, activeChainId, isActivating, account, library, activate, deactivate, chainId }) => {
	
//   const [loading, setLoading] = useState(false);
//   // const { activate, deactivate, library } = useWeb3React();
//   const { balance, setBalance } = useWalletCustom()

 

//  const onConnectMetaMask = async () => {
//     // List of acceptable chain IDs
//     const acceptableChainIds = ["1", "300", "324", "8453", "84532"]; // Add all acceptable chain IDs as strings

//     try {
//       // Check if Ethereum object and chainId are available
//       if (window.ethereum) {
//         const currentChainId = await window.ethereum.request({ method: 'eth_chainId' }); // This returns a hex string like '0x1' for Ethereum Mainnet

//         // Check if the current chain ID is not in the acceptable list (convert hex to decimal string for comparison)
//         if (!acceptableChainIds.includes(parseInt(currentChainId, 16).toString())) {
//           // Find the first acceptable chainId to switch to (as an example)
//           const chainIdToSwitch = acceptableChainIds[2]; // This is a string, like "300"

//           try {
//             await window.ethereum.request({
//               method: "wallet_switchEthereumChain",
//               params: [{ chainId: Web3.utils.toHex(chainIdToSwitch) }], // chainId must be in hexadecimal with a 0x prefix
//             });
//           } catch (err) {
//             console.log("Network change rejected", err);
//           }
//         } else {
//           setLoading(true);
//           try {
//             // Assume `connector.activate` accepts a chain ID; this example activates the first acceptable chain
//             await connector.activate(parseInt(currentChainId, 16).toString());
//           } catch (err) {
//             console.log("User rejected the request", err);
//             setLoading(false);
//           }
//         }
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   // const onConnectMetaMask = async () => {
//   //   const chainId = "84532"
//   //   try {
//   //     if (
//   //       chainId &&
//   //       window.ethereum &&
//   //       window.ethereum.networkVersion !== chainId
//   //     ) {
//   //       try {
//   //         await window.ethereum.request({
//   //           method: "wallet_switchEthereumChain",
//   //           params: [{ chainId: Web3.utils.toHex(parseInt(chainId)) }],
//   //         });
//   //       } catch (err) {
//   //         console.log("Network changed rejected", err);
//   //       }
//   //     } else {
//   //       setLoading(true);
//   //       try {
//   //         await connector.activate(chainId);
//   //       } catch (err) {
//   //         console.log("User rejected the request", err);
//   //         setLoading(false);
//   //       }
//   //     }
//   //   } catch (error) {
//   //     console.log(error);
//   //   }
//   // };

//   // const onDisconnectMetaMask = () => {
//   //   if (connector.deactivate) {
//   //     connector.deactivate();
//   //   } else {
//   //     connector.resetState();
//   //   }
//   // };

//   useEffect(() => {
//       // actually, you can implement some kinds of sign functions here
//     if (account) setLoading(false);
//     const fetchBalance = async () => {
//       if (account && library) {
      	
//         const balance = await library.getBalance(account);
//         console.log('balance: ' + balance)
//         setBalance(balance);
//       }
//     };
//     if (account) {
//       fetchBalance();
//     }
    
//   }, [account, library, chainId]);

//    return (
//     <>
//       {isActive ? (
//         <>
//          <div className="flex items-center bg-gray-100 px-4 py-1 rounded-full">
// 		    <div className="flex items-center">
// 		      <i className="bx bx-wallet mr-2" /> {0.12 + ' ETH'}
// 		    </div>
// 		    <div
// 		      className="flex items-center bg-blue-500 text-white px-2 py-1 ml-4 rounded-full border border-blue-500 cursor-pointer hover:bg-gray-100 hover:text-gray-600 hover:border-blue-600"
		      
// 		    >
// 		      {formatAddress(account)}
// 		    </div>
// 		  </div>
//         </>
//       ) : (   
//         <button
//           type="button"
//           className={`ml-3 inline-flex items-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 ${loading ? "loading" : ""}`}
//           disabled={!!loading}
//           onClick={onConnectMetaMask}
//         >
          
//               Connect Wallet
           
//         </button>
//       )}
//     </>
//   );



// }

// export default ConnectButton