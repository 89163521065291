import { useState } from "react"
import { Outlet } from "react-router-dom"
import Header from "./Header"
import { MainContent } from "./MainContent"
import Footer from "./Footer"

export default function Layout() {
	return (
	<div className="lg:pl-72">
	<Header />
	<div className="content">

		<Outlet />

	</div>
	<Footer />

	</div>

	)
}