import React from "react"
import { Dialog } from "@headlessui/react"
import SpinnerWrap from "./SpinnerWrap"

const ConfirmSubmitModal = ({
    showConfirmSubmitModal,
    setShowConfirmSubmitModal,
    modalAlertMessage,
    setModalAlertMessage,
    alertColor,
    setAlertColor,
    disableAllButtons,
    showConfirmLoading,
    handleClickConfirm,
    modalTitle,
    modalText,
    confirmButtonText,
    confirmButtonLoading,
    className = "",
    handleCloseModal = () => {},
    children,
    ...rest
}) => {
    const closeAndResetModal = () => {
        localStorage.setItem("lastActive", new Date().getTime())
        setShowConfirmSubmitModal(false)
        setAlertColor("")
        setModalAlertMessage("")
        handleCloseModal()
    }

    return (
        <Dialog
            open={showConfirmSubmitModal}
            onClose={!disableAllButtons && !showConfirmLoading ? closeAndResetModal : () => {}}
            className={`fixed z-10 inset-0 overflow-y-auto ${className}`}
        >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>

                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <form>
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                        {modalTitle}
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">{modalText}</p>
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            {modalAlertMessage && (
                                <div
                                    className={`mb-4 px-4 py-3 rounded relative ${
                                        alertColor === "success"
                                            ? "bg-green-100 border-green-400 text-green-700"
                                            : alertColor === "danger"
                                            ? "bg-red-100 border-red-400 text-red-700"
                                            : "bg-yellow-100 border-yellow-400 text-yellow-700"
                                    }`}
                                    role="alert"
                                >
                                    <span className="block sm:inline">{modalAlertMessage}</span>
                                    {!disableAllButtons && (
                                        <span
                                            className="absolute top-0 bottom-0 right-0 px-4 py-3"
                                            onClick={() => setModalAlertMessage("")}
                                        >
                                            <svg
                                                className={`fill-current h-6 w-6 ${
                                                    alertColor === "success"
                                                        ? "text-green-500"
                                                        : alertColor === "danger"
                                                        ? "text-red-500"
                                                        : "text-yellow-500"
                                                }`}
                                                role="button"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                                <title>Close</title>
                                                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                                            </svg>
                                        </span>
                                    )}
                                </div>
                            )}
                            <button
                                type="submit"
                                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm ${
                                    showConfirmLoading || disableAllButtons ? "opacity-50 cursor-not-allowed" : ""
                                }`}
                                disabled={showConfirmLoading || disableAllButtons}
                            >
                                <SpinnerWrap
                                    showSpinner={showConfirmLoading}
                                    txtTrue={confirmButtonLoading}
                                    txtFalse={confirmButtonText}
                                    disabled={showConfirmLoading || disableAllButtons}
                                />
                            </button>
                            <button
                                type="button"
                                className={`mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm ${
                                    showConfirmLoading || disableAllButtons ? "opacity-50 cursor-not-allowed" : ""
                                }`}
                                onClick={closeAndResetModal}
                                disabled={showConfirmLoading || disableAllButtons}
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Dialog>
    )
}

export default ConfirmSubmitModal