import HeaderSection from "../Layout/HeaderSection"
const products = [
  {
    id: 1,
    name: 'Hue Socks',
    href: '#',
    price: '$20',
    description: 'Cotton ribbed athletic socks. Crew length. Perfect for sports or lounging. Black with Hue design woven into fabric. 87% Cotton, 11% Nylon, 2% Spandex. Moisture-wicking, ultra-breathable & antimicrobial. Sustainably made. US Men 6-12, Women 7-13. Holders of this NFT can redeem it for the physical socks or trade it on secondary markets.',
    // options: '8 colors',
    imageSrc: 'https://arweave.net/409lS53mtVAn5KNvGSH0PBSnKYNHuVrQkHgb8j_DvrQ',
    imageAlt: 'Eight shirts arranged on table in black, olive, grey, blue, white, red, mustard, and green.',
  },
  {
    id: 2,
    name: 'One-of-a-kind Hue Art by Epic One',
    href: '#',
    price: '$3500',
    description: 'Mixed-medium wall-mountable Hue art with matching Hue NFT #280. 18" × 18" x 4". 64 x 2* primed & painted wood cubes screw-mounted on wood with primed & painted white wood frame. Ready to hang and built to last. Owner will receive the matching Hue NFT that doubles as a certificate of ownership. Can be purchased here or by buying the Hue NFT #280 listed on 2kmarkets.com.',
    // options: 'Black',
    imageSrc: 'https://arweave.net/oBfAcd6kDNIc39rDuUBh9Fg1alzfbmf6bBwNE86rXLk',
    imageAlt: 'Front of plain black t-shirt.',
  },
  {
    id: 3,
    name: 'Hue Cap',
    href: '#',
    price: '$35',
    description: 'This NFT grants ownership of a Hue Cap, a classic dad cap that elegantly combines traditional style with sustainable practices. As the NFT holder, you can redeem this token for the physical cap or trade it on secondary markets.',
    imageSrc: 'https://arweave.net/MzHUUZ3uHmInfTWZw-YONIcjkxHgGV_yimmG9qrhRQ4',
    imageAlt: 'Eight shirts arranged on table in black, olive, grey, blue, white, red, mustard, and green.',
  },
  {
    id: 4,
    name: 'Hue Sticker Pack',
    href: '#',
    price: '$5',
    description: 'This NFT grants ownership of the exclusive Hue Sticker Pack. The pack includes seven unique stickers: mini Hue stickers in yellow, orange, and purple; a Hue name sticker; a Hue logo sticker; a "Paint the Future" Hue sticker; and a special glow-in-the-dark Hue vampire sticker. As an NFT holder, you can choose to redeem this for the physical sticker pack or trade it on secondary markets.',
    // options: 'Black',
    imageSrc: 'https://arweave.net/gLqfzn_TWw8DgT1tibLsrYWYuUGOy0V4U0i-VJsy9Lc',
    imageAlt: 'Front of plain black t-shirt.',
  },
  {
    id: 5,
    name: 'Hue Canvas Tote Bag',
    href: '#',
    price: '$30',
    description: 'Hue “Paint the Future” tote bag. 100% Cotton. 15” W x 16” H with 20” handles.',
    imageSrc: 'https://arweave.net/YgkM5zZ1BysP_xd_b6nJ1j_ZikhN3IkPc3byVj12yRQ',
    imageAlt: 'Eight shirts arranged on table in black, olive, grey, blue, white, red, mustard, and green.',
  },
  {
    id: 6,
    name: 'Hue Kit NFT',
    href: '#',
    price: '$65',
    description: `This NFT grants ownership of the Hue Kit, a comprehensive collection featuring some of Hue's most popular items. Included in the kit are a Hue Cap, a pair of Hue Socks, a Hue Sticker Pack, and a Hue Canvas Tote Bag. Holders of this NFT can redeem it for the physical items included in the kit or choose to trade it on secondary markets.`,
    // options: 'Black',
    imageSrc: 'https://arweave.net/wIJ--xAyUM56bhAgTE5RFiDvYywazwyzwD-uP8_Bxn4',
    imageAlt: 'Front of plain black t-shirt.',
  },
  // More products...
]

export default function Shop() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-6 sm:px-6 sm:py-6 lg:max-w-7xl lg:px-8">
        <HeaderSection title="Welcome to the Hue Merch Store" subtext="Each NFT can be redeemed for its matching physical item and have it delivered for a delivery fee." />

        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {products.map((product) => (
            <div
              key={product.id}
              className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
            >
              <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                />
              </div>
              <div className="flex flex-1 flex-col space-y-2 p-4">
                <h3 className="text-sm font-medium text-gray-900">
                  <a href={product.href}>
                    <span aria-hidden="true" className="absolute inset-0" />
                    {product.name}
                  </a>
                </h3>
                <p className="text-sm text-gray-500">{product.description}</p>
                <div className="flex flex-1 flex-col justify-end">
                  <p className="text-sm italic text-gray-500">{product.options}</p>
                  <p className="text-base font-medium text-gray-900">{product.price} USDC.e</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
