// walletService.js
import { Web3Provider } from '@ethersproject/providers';
import { InjectedConnector } from '@web3-react/injected-connector';

const injectedConnector = new InjectedConnector({
  supportedChainIds: [324], // Add your supported chain IDs here
});

export const connectWallet = async (connector) => {
  try {
    await connector.activate();
  } catch (error) {
    console.error('Failed to connect:', error);
  }
};

export const switchChain = async (library, targetChainId) => {
  try {
    await library.provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: `0x${targetChainId.toString(16)}` }],
    });
  } catch (error) {
    console.error('Failed to switch chain:', error);
  }
};

export const getBalance = async (library, account) => {
  console.log('library: ' + library)
  if (account && library) {
    const balance = await library.getBalance(account);
    return balance;
  }
  return null;
};